import { FlowPostData } from '@/features/flows/types';
import { ModeratedFeedItemV1, ModeratedFeedItemV2 } from '@/features/posts/types';
import config from 'config';
import { scopes } from 'constants/scopes';
import { Session } from 'next-auth';
import { getCsrfToken } from 'next-auth/react';
import {
  CharityType,
  LoginResponse,
  MyProfile,
  PostOverview,
  ProfileSocialOverview,
  PublicUserProfile,
  PublicUserProfileWithSocialStatus,
  TokenResponse,
} from 'types';

export const updateSession = async (newSession: Session) => {
  await fetch(`/api/auth/session`, {
    method: 'POST',
    headers: {
      'content-type': 'application/json',
    },
    body: JSON.stringify({
      csrfToken: await getCsrfToken(),
      data: newSession,
    }),
  });
};

export const fetchMachineToken = async () => {
  const tokenRes = await fetch(`${config.identityApi}/machine/token`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      clientId: config.auth.machineId,
      clientSecret: config.auth.machineSecret || '',
      scopes: scopes.machineToken,
    }),
  });

  if (tokenRes.status !== 200) return null;
  return ((await tokenRes.json()) as TokenResponse).accessToken;
};

export const login = async (email: string, password: string, machineToken: string) => {
  const loginRes = await fetch(`${config.identityApi}/web/citizen/login`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${machineToken}` },
    body: JSON.stringify({
      clientId: config.auth.clientId,
      clientSecret: config.auth.clientSecret,
      username: email,
      password,
      scopes: scopes.accessToken,
    }),
  });
  if (loginRes.status !== 200) return null;

  return (await loginRes.json()) as LoginResponse;
};

export const refreshToken = async (refreshToken: string, machineToken: string) => {
  const refreshTokenRes = await fetch(`${config.identityApi}/web/refresh`, {
    method: 'POST',
    body: JSON.stringify({
      clientId: config.auth.clientId,
      clientSecret: config.auth.clientSecret,
      refreshToken,
      scopes: scopes.refreshToken,
    }),
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${machineToken}`,
    },
  });
  if (refreshTokenRes.status !== 200) return null;
  return (await refreshTokenRes.json()) as LoginResponse;
};

export const googleLogin = async (idToken: string) => {
  const loginRes = await fetch(`${config.identityApi}/web/google/login`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      idToken,
      clientId: config.auth.clientId,
      clientSecret: config.auth.clientSecret,
      scopes: scopes.accessToken,
    }),
  });
  if (loginRes.status !== 200) return null;

  return (await loginRes.json()) as LoginResponse;
};

export const appleLogin = async (idToken: string) => {
  const loginRes = await fetch(`${config.identityApi}/web/apple/login`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      clientId: config.auth.clientId,
      clientSecret: config.auth.clientSecret,
      idToken,
      scopes: scopes.accessToken,
    }),
  });
  if (loginRes.status !== 200) return null;

  return (await loginRes.json()) as LoginResponse;
};

export const getMyProfile = async (accessToken: string) => {
  const profileRes = await fetch(`${config.usersAPI}/influencers/me`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'x-api-version': '5.0',
      Authorization: `Bearer ${accessToken}`,
    },
  });

  if (profileRes.status !== 200) return null;
  const profile = (await profileRes.json()) as MyProfile;

  const socialInfoRes = await fetch(`${config.graphsAPI}/influencers/me/social-overview`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
  });

  if (socialInfoRes.status !== 200) return null;

  const socialInfo = (await socialInfoRes.json()) as ProfileSocialOverview;
  return { ...profile, ...socialInfo } as MyProfile;
};

export const getPublicProfileAsLoggedInUser = async (username: string, accessToken: string) => {
  const profileRes = await fetch(`${config.usersAPI}/influencers/profile/${username}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
  });

  if (profileRes.status !== 200) return null;

  const profile = (await profileRes.json()) as PublicUserProfileWithSocialStatus;

  const socialInfoRes = await fetch(`${config.socialApi}/citizens/${profile.id}/social-overview`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
  });

  if (socialInfoRes.status !== 200) return null;

  const socialInfo = (await socialInfoRes.json()) as ProfileSocialOverview;
  return { ...profile, ...socialInfo } as PublicUserProfileWithSocialStatus;
};

export const getPublicProfile = async (username: string) => {
  const machineToken = await fetchMachineToken();
  const profileRes = await fetch(`${config.usersAPI}/sharing/citizen/${username}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${machineToken}`,
    },
  });

  if (profileRes.status !== 200) return null;

  const profile = await profileRes.json();

  return profile as PublicUserProfile;
};

export const getPublicCharity = async (charityId: string) => {
  const machineToken = await fetchMachineToken();
  const charityRes = await fetch(
    `${config.charitiesAPI}/sharing/${charityId}?width=500&height=500`,
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${machineToken}`,
      },
    }
  );

  if (charityRes.status !== 200) return null;

  const charity = await charityRes.json();

  return charity as CharityType;
};

export const getPublicPost = async (postId: string) => {
  const machineToken = await fetchMachineToken();
  const postRes = await fetch(`${config.contentApi}/v1/sharing/creators-content/${postId}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${machineToken}`,
    },
  });

  if (postRes.status !== 200) return null;

  const post = (await postRes.json()) as ModeratedFeedItemV1;

  const postOverviewRes = await fetch(`${config.socialApi}/v2/sharing/${postId}/overview`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${machineToken}`,
    },
  });

  if (postOverviewRes.status !== 200) return null;

  const overviewInfo = (await postOverviewRes.json()) as PostOverview;
  return { ...post, ...overviewInfo };
};

export const getPostAsLoggedInUser = async (postId: string, accessToken: string) => {
  const postRes = await fetch(`${config.contentApi}/v1/creators-content/${postId}`, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
  if (postRes.status !== 200) return null;

  const post = (await postRes.json()) as ModeratedFeedItemV1;

  const postOverviewRes = await fetch(`${config.socialApi}/v3/feeds/${postId}/overview`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
  });

  if (postOverviewRes.status !== 200) return null;

  const overviewInfo = (await postOverviewRes.json()) as PostOverview;
  return { ...post, ...overviewInfo };
};

export const getFlowPost = async (flowPostId: string) => {
  const machineToken = await fetchMachineToken();
  const flowPostRes = await fetch(`${config.flowApi}/v2/web/flows/${flowPostId}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${machineToken}`,
    },
  });

  if (flowPostRes.status !== 200) return null;

  const flowPost = await flowPostRes.json();

  return flowPost as FlowPostData;
};
