import {
  BaseQueryApi,
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError,
  FetchBaseQueryMeta,
} from '@reduxjs/toolkit/dist/query';
import { setMachineToken } from 'store/state/authSlice';
import { RootState } from 'store/store';
import { fetchMachineToken } from './fetch';
import { isExpired } from './token';

interface ReauthProps {
  args: string | FetchArgs;
  api: BaseQueryApi;
  extraOptions: object;
  baseQuery: BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError, {}, FetchBaseQueryMeta>;
}

export const handleReauth = async ({ args, api, extraOptions, baseQuery }: ReauthProps) => {
  const getState = api.getState() as RootState;
  const token = getState.auth.machineToken;
  if (token && !isExpired(token)) return;
  try {
    const machineToken = await fetchMachineToken();
    if (machineToken) {
      // store the new token
      api.dispatch(setMachineToken(machineToken));
    } else {
      api.dispatch(setMachineToken(null));
    }
  } catch (error) {
    api.dispatch(setMachineToken(null));
  }
};
