import jwtDecode from 'jwt-decode';

export interface DecodedTokenProps {
  exp: number;
  sub: string;
}

export const decodeToken = (token: string) => jwtDecode(token);

export const isExpired = (token: string) => {
  const { exp } = decodeToken(token) as DecodedTokenProps;
  const clientExpire = Math.round(new Date().getTime() / 1000 + 180);
  const isExpired = clientExpire >= exp;
  return isExpired;
};
