import { useSession } from 'next-auth/react';
import { FC, PropsWithChildren, useCallback, useEffect } from 'react';

const NetworkProvider: FC<PropsWithChildren> = ({ children }) => {
  const { update } = useSession();

  const onlineListener = useCallback(() => {
    update();
  }, [update]);

  useEffect(() => {
    window.addEventListener('online', onlineListener);

    return () => {
      window.removeEventListener('online', onlineListener);
    };
  }, [onlineListener]);
  return <>{children}</>;
};

export default NetworkProvider;
